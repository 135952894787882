<template>
  <div>
    <div class="entry">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">部署情報登録・修正</h3>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div class="uk-container">
          <ul class="uk-list">
            <template
              v-for="i in data_num"
              :key="i"
              class="uk-flex uk-flex-center uk-flex-middle"
            >
              <li v-if="!data[i - 1].deleted_flg">
                <input
                  type="text"
                  name=""
                  class="uk-input uk-form-small uk-form-width-small"
                  v-model="data[i - 1].busyo_name"
                />&nbsp;
                <span
                  uk-icon="icon: trash"
                  class="uk-icon uk-text-danger"
                  @click="set_deleted_flg(i - 1)"
                ></span>
              </li>
            </template>
          </ul>

          <div class="uk-width-1-1 uk-margin">
            <div>
              <span uk-icon="icon: plus-circle" @click="add_data"
                >項目欄追加&nbsp;</span
              >
            </div>
          </div>
        </div>

        <div class="uk-container uk-flex uk-flex-center">
          <div class="uk-width-1-3 uk-padding">
            <button
              type="button"
              name="button"
              @click="$router.back()"
              class="uk-button uk-button-default uk-width-1-1"
            >
              &lt;&nbsp;戻る
            </button>
          </div>
          <div class="uk-width-1-3 uk-padding">
            <button
              type="button"
              name="button"
              @click="send_data()"
              class="uk-button uk-button-primary uk-width-1-1 mal10"
            >
              更新
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      data: "",
      data_num: 0,
      do_something: true,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "部署情報";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.checkLogined();
    this.get_info_busyo();
  },
  methods: {
    checkLogined() {
      if (!this.$store.state.login_status_c) {
        this.$router.replace("/client-login");
      }
    },
    get_info_busyo() {
      this.$axios
        .post("/client/check_info_busyo", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
        })
        .then(
          function (response) {
            //eslint-disable-line
            if (response.data.flg) {
              console.log(response.data);
              this.data = response.data.busyo;
              console.log(this.data);
              this.data_num = this.data.length;
              for (let i = 0; i < this.data.length; i++) {
                this.data[i]["deleted_flg"] = 0;
              }
            } else {
              this.flg = false;
              alert(
                "エラーが発生しました。\nお手数ですがもう一度やり直してください。"
              );
              this.$router.push("/error");
            }
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        );
    },
    add_data() {
      console.log("add_data");
      this.data.push({ busyo_name: "", deleted_flg: 0 });
      this.data_num += 1;
    },
    send_data() {
      if (!this.do_something) return;
      this.do_something = false;
      console.log("send_data");
      console.log(this.data);
      this.$axios
        .post("/client/update_info_busyo", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
          busyo: this.data,
        })
        .then(
          function (response) {
            //eslint-disable-line
            console.log("レスポンス");
            if (response.data.flg) {
              alert("部署データを更新しました");
              this.$router.push("/client-menu");
            } else {
              alert("部署データの更新に失敗しました");
            }
            this.do_something = true;
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
            this.do_something = true;
          }.bind(this)
        );
    },
    set_deleted_flg(index) {
      console.log("delete" + index);
      this.data[index]["deleted_flg"] = 1;
    },
  },
};
</script>
